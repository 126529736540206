<template>
  <div class="shell-index">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item>会议壳</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="over-num">
      <div class="item">
        <div class="num">125</div>
        <div class="title">已创建会议数量</div>
      </div>
      <div class="item">
        <div class="num">125</div>
        <div class="title">已注册会员数</div>
      </div>
      <div class="item">
        <div class="num">125</div>
        <div class="title">总访问量</div>
      </div>
    </div>
    <div class="panel-title">
      管理菜单入口
    </div>
    <div class="menu-list">
      <div class="menu-item">
        <div class="title">会议管理</div>
        <div class="enter-btn" @click="enterMeetingList">进入 ></div>
      </div>
      <div class="menu-item">
        <div class="title">会员管理</div>
        <div class="enter-btn" @click="enterMember">进入 ></div>
      </div>
      <div class="menu-item">
        <div class="title">访问记录</div>
        <div class="enter-btn" @click="enterRecord">进入 ></div>
      </div>
      <div class="menu-item">
        <div class="title">公用背景</div>
        <div class="enter-btn" @click="enterBg">进入 ></div>
      </div>
      <div class="menu-item">
        <div class="title">公用图标</div>
        <div class="enter-btn" @click="enterIcon">进入 ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {}
  },
  created () {
  },
  methods: {
    enterMeetingList () {
      this.$router.push('/admin/apps/shell/meeting-list')
    },
    // 公共背景
    enterBg () {
      this.$router.push('/admin/apps/shell/bg')
    },
    // 公共图标
    enterIcon () {
      this.$router.push('/admin/apps/shell/icon')
    },
    // 会员管理
    enterMember () {
      this.$router.push('/admin/apps/shell/member')
    },
    // 访问记录
    enterRecord () {
      this.$router.push('/admin/apps/shell/record')
    }
  }
}
</script>

<style scoped>
.over-num{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 64%;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 0;
}
.over-num .item{
  background-color: rgba(255,255,255,0.8);
  width: 30%;
  padding: 20px 0;
}
.over-num .item .num{
  color: #606266;
  font-weight: bold;
  font-size: 24px;
}
.over-num .item .title{
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}
.menu-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.menu-list .menu-item{
  width: 21%;
  margin: 2%;
  background-color: #FFFFFF;
  padding: 40px 0;
  position: relative;
}
.menu-list .menu-item .title{
  font-size: 14px;
  font-weight: bold;
  color: #606266;
  padding-bottom: 20px;
}
.menu-list .menu-item .enter-btn{
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  border: 1px solid #606266;
  border-radius: 10px;
  padding: 0 10px;
  cursor: pointer;
}
.panel-title{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-top: 60px;
}
</style>
